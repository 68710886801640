import React from "react";
import classnames from "classnames";
import "./Match.css";
import ChevronRightIcon from "./chevron-right";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const yy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${dd}-${mm}-${yy}`;
};

function Match({ match, opened, onClick }) {
  const containerClasses = classnames("match-container", {
    "match-container--opened": opened === match.id,
  });
  return (
    <div className={containerClasses}>
      <div onClick={onClick(match.id)} className="match-header">
        <span className="match-date">{formatDate(match.date)}</span>
        <span className="match-title">{match.title}</span>
        <ChevronRightIcon className="match-open-btn" />
      </div>
      {opened === match.id && (
        <div dangerouslySetInnerHTML={{ __html: match.embed }}></div>
      )}
    </div>
  );
}

export default Match;
