import React, { useState, useEffect } from "react";
import Match from "./Match";
import "./App.css";

const filters = [
  "ARMENIA",
  "AUSTRALIA",
  "AZERBAIJAN",
  "BELARUS",
  "BELGIUM",
  "BRASIL",
  "BULGARIA",
  "CANADA",
  "CHINA",
  "COSTA RICA",
  "CROATIA",
  "CYPRUS",
  "ESTONIA",
  "GEORGIA",
  "IRAN",
  "JAPAN",
  "KAZAKHSTAN",
  "KOREA",
  "LATVIA",
  "LITHUANIA",
  "MEXICO",
  "NORTH MACEDONIA",
  "NORWAY",
  "PERU",
  "POLAND",
  "PARAGUAY",
  "QATAR",
  "ROMANIA",
  "RUSSIA",
  "SAUDI ARABIA",
  "SCOTLAND",
  "SERBIA",
  "SWEDEN",
  "SLOVENIA",
  "SWITZERLAND",
  "UKRAINE",
  "URUGUAY",
  "USA",
  "UZBEKISTAN",
];

function App() {
  const [result, setResult] = useState();
  const [opened, setOpened] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch("https://www.scorebat.com/video-api/v1/");
      const result = await data.json();
      const filtered = result.filter((r) => {
        const competName = r.competition.name;
        return (
          filters.find((filter) => competName.includes(filter)) === undefined
        );
      });
      setResult(filtered.map((x, id) => ({ ...x, id })));
    };
    fetchData();
  }, []);

  const clicked = (id) => () => {
    opened === id ? setOpened() : setOpened(id);
  };

  return (
    <div className="App">
      {result &&
        result.map((match) => {
          return (
            <Match
              key={match.id}
              match={match}
              opened={opened}
              onClick={clicked}
            />
          );
        })}
    </div>
  );
}

export default App;
